.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.tracking-tight {
  letter-spacing: -0.01em;
}

/* Custom Scrollbar for Webkit Browsers */
::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar thumb */
  border-radius: 6px; /* Rounded corners */
  border: 3px solid #f1f1f1; /* Space around thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color when hovering over the scrollbar thumb */
}

/* Custom Scrollbar for Firefox */
* {
  scrollbar-width: thin; /* Width of the scrollbar */
  scrollbar-color: #888 #f1f1f1; /* Color of the scrollbar thumb and track */
}

.polygon-section {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  /* Adjust the following values for more or less pronounced angles */
}