@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&family=Playfair+Display:ital,wght@0,700;1,400&display=fallback");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

@import "tailwindcss/base";
@import "tailwindcss/components";

/* Additional styles */
@import "additional-styles/utility-patterns.css";
@import "additional-styles/range-slider.css";
@import "additional-styles/toggle-switch.css";
@import "additional-styles/theme.css";

@import "tailwindcss/utilities";

/* Additional Tailwind directives: https://tailwindcss.com/docs/functions-and-directives/#responsive */
@layer utilities {
  .rtl {
    direction: rtl;
  }
}

a {
  font-family: "Montserrat", sans-serif; /* Use Montserrat for navigation links */
}

/* See Alpine.js: https://github.com/alpinejs/alpine#x-cloak */
[x-cloak=""] {
  display: none;
}
