.form-input:focus,
.form-textarea:focus,
.form-multiselect:focus,
.form-select:focus,
.form-checkbox:focus,
.form-radio:focus {
    @apply ring-0;
}

/* Hamburger button */
.hamburger svg>*:nth-child(1),
.hamburger svg>*:nth-child(2),
.hamburger svg>*:nth-child(3) {
    transform-origin: center;
    transform: rotate(0deg);
}

.hamburger svg>*:nth-child(1) {
    transition: y 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19), opacity 0.1s ease-in;
}

.hamburger svg>*:nth-child(2) {
    transition: transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger svg>*:nth-child(3) {
    transition: y 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19), width 0.1s 0.25s ease-in;
}

.hamburger.active svg>*:nth-child(1) {
    opacity: 0;
    y: 11;
    transform: rotate(225deg);
    transition: y 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.1s 0.12s ease-out;
}

.hamburger.active svg>*:nth-child(2) {
    transform: rotate(225deg);
    transition: transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger.active svg>*:nth-child(3) {
    y: 11;
    transform: rotate(135deg);
    transition: y 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), width 0.1s ease-out;
}

/* Custom AOS distance */
@media screen {
    html:not(.no-js) [data-aos=fade-up] {
        -webkit-transform: translate3d(0, 10px, 0);
        transform: translate3d(0, 10px, 0);
    }

    html:not(.no-js) [data-aos=fade-down] {
        -webkit-transform: translate3d(0, -10px, 0);
        transform: translate3d(0, -10px, 0);
    }

    html:not(.no-js) [data-aos=fade-right] {
        -webkit-transform: translate3d(-10px, 0, 0);
        transform: translate3d(-10px, 0, 0);
    }

    html:not(.no-js) [data-aos=fade-left] {
        -webkit-transform: translate3d(10px, 0, 0);
        transform: translate3d(10px, 0, 0);
    }

    html:not(.no-js) [data-aos=fade-up-right] {
        -webkit-transform: translate3d(-10px, 10px, 0);
        transform: translate3d(-10px, 10px, 0);
    }

    html:not(.no-js) [data-aos=fade-up-left] {
        -webkit-transform: translate3d(10px, 10px, 0);
        transform: translate3d(10px, 10px, 0);
    }

    html:not(.no-js) [data-aos=fade-down-right] {
        -webkit-transform: translate3d(-10px, -10px, 0);
        transform: translate3d(-10px, -10px, 0);
    }

    html:not(.no-js) [data-aos=fade-down-left] {
        -webkit-transform: translate3d(10px, -10px, 0);
        transform: translate3d(10px, -10px, 0);
    }

    html:not(.no-js) [data-aos=zoom-in-up] {
        -webkit-transform: translate3d(0, 10px, 0) scale(.6);
        transform: translate3d(0, 10px, 0) scale(.6);
    }

    html:not(.no-js) [data-aos=zoom-in-down] {
        -webkit-transform: translate3d(0, -10px, 0) scale(.6);
        transform: translate3d(0, -10px, 0) scale(.6);
    }

    html:not(.no-js) [data-aos=zoom-in-right] {
        -webkit-transform: translate3d(-10px, 0, 0) scale(.6);
        transform: translate3d(-10px, 0, 0) scale(.6);
    }

    html:not(.no-js) [data-aos=zoom-in-left] {
        -webkit-transform: translate3d(10px, 0, 0) scale(.6);
        transform: translate3d(10px, 0, 0) scale(.6);
    }

    html:not(.no-js) [data-aos=zoom-out-up] {
        -webkit-transform: translate3d(0, 10px, 0) scale(1.2);
        transform: translate3d(0, 10px, 0) scale(1.2);
    }

    html:not(.no-js) [data-aos=zoom-out-down] {
        -webkit-transform: translate3d(0, -10px, 0) scale(1.2);
        transform: translate3d(0, -10px, 0) scale(1.2);
    }

    html:not(.no-js) [data-aos=zoom-out-right] {
        -webkit-transform: translate3d(-10px, 0, 0) scale(1.2);
        transform: translate3d(-10px, 0, 0) scale(1.2);
    }

    html:not(.no-js) [data-aos=zoom-out-left] {
        -webkit-transform: translate3d(10px, 0, 0) scale(1.2);
        transform: translate3d(10px, 0, 0) scale(1.2);
    }
}